import { useState } from "react";
import { EditableText, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import PropertySelect from "@components/Shared/PropertySelect/PropertySelect";
import { useAppNavigate } from "@router/hooks";
import { IAnalysisInput } from "@store/Analysis/AnalysisInputStore";

import { sanitizeInput } from "../utils/analysisUtils";

import styles from "./AnalysisInputListItem.module.scss";

type AnalysisInputListItemProps = {
  analysisInput: IAnalysisInput;
  isDragging?: boolean;
  dragListeners?: SyntheticListenerMap;
};

function AnalysisInputListItem({ analysisInput, isDragging, dragListeners }: AnalysisInputListItemProps) {
  const [label, setLabel] = useState(analysisInput.label);
  const { navigateToProperty } = useAppNavigate();

  const handleDelete = () => {
    analysisInput.analysis?.deleteInput(analysisInput);
  };

  const handleLabelChange = (value: string) => {
    setLabel(sanitizeInput(value));
  };

  const handleSetLabel = (value: string) => {
    const sanitizedLabel = sanitizeInput(value);
    setLabel(sanitizedLabel);
    analysisInput.setLabel(sanitizedLabel);
  };

  const contextMenu = (
    <Menu>
      <MenuItem e2eIdentifiers="clear-value" text="Clear value" icon="eraser" onClick={() => analysisInput.setValue("")} />
      <MenuItem
        e2eIdentifiers="navigate"
        text="Navigate to property"
        icon="open-application"
        disabled={!analysisInput.propertyReference}
        onClick={() => analysisInput.propertyReference && navigateToProperty(analysisInput.propertyReference?.id)}
      />
      <MenuDivider />
      <MenuItemDelete onDelete={handleDelete} />
    </Menu>
  );

  return (
    <div className={classNames("dnd-drag-handle-item", styles.analysisInputListItem, { [styles.dragging]: isDragging })}>
      <Popover placement="bottom-start" content={contextMenu}>
        <Button {...dragListeners} minimal intent={Intent.NONE} small icon="more" e2eIdentifiers="open-menu" />
      </Popover>
      <EditableText
        key={`${analysisInput.id}-${analysisInput.label}`}
        placeholder="Input variable label"
        defaultValue={label}
        onChange={handleLabelChange}
        onConfirm={handleSetLabel}
        confirmOnEnterKey
      />
      <div className={styles.spacer} />
      <PropertySelect scalarOnly selected={analysisInput.propertyReference} onChange={analysisInput.setPropertyReference} />
    </div>
  );
}

export default observer(AnalysisInputListItem);
