import { useCallback, useContext, useEffect } from "react";

import { BACKSPACE_KEY, DELETE_KEY, ESCAPE_KEY } from "@constants/keys";
import { isInputFocused } from "@utilities";
import { isMetaKey } from "@utilities/Keyboard";

import DragToSelectContext from "./DragToSelectContext";
import { TDragToSelectContext } from "./types";

interface IUseDragToSelectParams {
  allIds: string[];
  selectedIds: string[];
  onDelete?(ids: string[]): void;
  onSelect(ids: string[]): void;
  onSelectAll(allIds: string[]): void;
  onResetSelection(): void;
}
export const useDragToSelect = (params: IUseDragToSelectParams): TDragToSelectContext => {
  const { allIds, selectedIds, onDelete, onSelect, onSelectAll, onResetSelection } = params;
  const ctx = useContext(DragToSelectContext);

  useEffect(() => {
    onSelect(ctx.selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.selectedIds.length, onSelect]);

  const keyUpHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === BACKSPACE_KEY || e.key === DELETE_KEY) {
        onDelete?.(selectedIds);
      }
      if (e.key === ESCAPE_KEY) {
        onResetSelection();
      }
    },
    [onDelete, onResetSelection, selectedIds]
  );

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "a" && isMetaKey(e) && !isInputFocused()) {
        e.preventDefault();
        (document.activeElement as HTMLElement)?.blur();
        onSelectAll(allIds);
      }
    },
    [allIds, onSelectAll]
  );

  useEffect(() => {
    window.addEventListener("keyup", keyUpHandler);
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keyup", keyUpHandler);
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyUpHandler, keyDownHandler]);

  return ctx;
};
