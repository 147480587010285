import { useCallback, useEffect, useState } from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { Editor } from "@tiptap/react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { PresenceList } from "@components/Header/PresenceList";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { useEditorHandlers } from "@components/Reports/Editor/useEditorHandlers";
import ReportBlockConvertMenuItems from "@components/Reports/Page/ReportBlockConvertMenuItems";
import { getAllowedTypes } from "@components/Reports/Page/utils";
import DocumentActionsNav from "@components/Reports/ReportBlock/DocumentActionsNav";
import KbAnnotationPopover from "@components/Reports/ReportBlock/KbAnnotationPopover";
import { SortableItemComponent } from "@components/Shared";
import { CopyLinkMenuItem } from "@components/SmallComponents";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";

import ReportsEditor from "../Editor";

import "./ReportBlock.scss";

type Props = SortableItemComponent & {
  reportBlock: IReportBlock;
  report: IReport;
  hasSingleBlock: boolean;
  editable?: boolean;
  selected?: boolean;
  onFocus?(): void;
  onAddReportBlock(block: IReportBlock, type?: RollupEditorType.p, label?: string, above?: boolean): void;
};

const ReportBlock = (props: Props) => {
  const { report, reportBlock, selected, hasSingleBlock, dragListeners, onFocus, onAddReportBlock } = props;
  const workspace = appStore.workspaceModel;
  const [editor, setEditor] = useState<Editor>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { handleEnterKeyDown, handleBackspaceKeyDown } = useEditorHandlers({ reportBlock, editor, onAddReportBlock });
  const { handleModEnterKeyDown, handleArrowDownKey, handleArrowUpKey } = useEditorHandlers({ reportBlock, editor, onAddReportBlock });
  const { handleBlockUpdate, handleShiftEnterKeyDown } = useEditorHandlers({ reportBlock, editor, onAddReportBlock });
  const [annotationId] = useSearchParam("annId");
  const [commentId] = useSearchParam("commId");
  const { annotationList } = reportBlock;
  const { lastFocusedCommentId } = report;
  const hasValidAnnotation = !!(annotationId && annotationList?.hasAnnotation(annotationId));
  const hasFocusedOnComment = commentId === lastFocusedCommentId;

  const handleFocus = () => {
    appStore.env.setActiveReportBlock(reportBlock.id);
    onFocus?.();
  };

  const handleBlur = () => {
    appStore.env.clearActiveReportBlock();
  };

  const { handlePaste } = useEditorHandlers({ reportBlock, editor, onAddReportBlock, workspaceId: workspace?.id });

  const handleEditorReady = useCallback(
    (editor?: Editor | null) => {
      if (editor) {
        appStore.env.editors.set(reportBlock.id, editor);
        setEditor(editor);
      }
    },
    [reportBlock.id]
  );

  useEffect(() => {
    if (hasValidAnnotation && !annotationList?.isPopulatingAnnotations && !hasFocusedOnComment) {
      setIsPopoverOpen(true);
    }
  }, [annotationList?.isPopulatingAnnotations, hasValidAnnotation, hasFocusedOnComment]);

  useEffect(() => {
    if (editor) {
      editor.setOptions({ editorProps: { handlePaste } });
    }
  }, [handlePaste, editor]);

  const handleDelete = () => {
    if (appStore.ui.selectedReportBlockIds.length && appStore.ui.selectedReportBlockIds.includes(reportBlock.id)) {
      workspace?.deleteReportBlocks(appStore.ui.selectedReportBlockIds);
    } else {
      appStore.workspaceModel?.deleteReportBlock(reportBlock);
    }
  };

  const handleAdd = () => {
    const activeReport = reportBlock.parentReport as IReport;
    if (activeReport) {
      const currentBlockLiveIndex = activeReport.validatedBlocks.findIndex(b => b.id === reportBlock.id);
      const id = appStore.workspaceModel?.addReportBlock(activeReport, RollupEditorType.p, "", currentBlockLiveIndex + 1);
      setTimeout(() => {
        const newBlockElement = id && document.getElementById(id);
        newBlockElement && (newBlockElement?.firstChild as HTMLDivElement).focus();
      }, 50);
    }
  };

  const renderDragButtonMenu = () => (
    <Menu>
      <ReportBlockConvertMenuItems reportBlock={reportBlock} allowedTypes={getAllowedTypes(reportBlock.type)} />
      <MenuDivider title="Actions" />
      <CopyLinkMenuItem node={reportBlock} />
      <MenuItem icon="comment" text="Comment" e2eIdentifiers="add-comment" onClick={() => setIsPopoverOpen(true)} />
      <MenuItemDelete onDelete={handleDelete} />
    </Menu>
  );

  return (
    <div data-id={reportBlock.id} className={classNames("report-block", { "report-block--selected": selected })}>
      <DocumentActionsNav
        className="actions-nav"
        hasSingleBlock={hasSingleBlock}
        dragListeners={dragListeners}
        onAdd={handleAdd}
        dragButtonMenuElement={renderDragButtonMenu()}
      />
      <PresenceList entityId={reportBlock.id} />
      <KbAnnotationPopover report={report} reportBlock={reportBlock} isOpen={isPopoverOpen} onInteraction={setIsPopoverOpen}>
        <ReportsEditor
          onEditorReady={handleEditorReady}
          reportBlock={reportBlock}
          onUpdate={handleBlockUpdate}
          eventHandlers={{
            onFocus: handleFocus,
            onBlur: handleBlur,
            onEnterKeyDown: handleEnterKeyDown,
            onModEnterKeyDown: handleModEnterKeyDown,
            onShiftEnterKeyDown: handleShiftEnterKeyDown,
            onBackspaceKeyDown: handleBackspaceKeyDown,
            onArrowDownKey: handleArrowDownKey,
            onArrowUpKey: handleArrowUpKey,
          }}
        />
      </KbAnnotationPopover>
    </div>
  );
};

export default observer(ReportBlock);
