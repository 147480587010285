// /import order matters for mescius packages, hence the below directive
// eslint-disable-next-line simple-import-sort/imports
import { IAnalysis } from "@store/Analysis/AnalysisStore";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";

import * as GC from "@mescius/spread-sheets";
import * as DR from "@mescius/spread-sheets-designer";
import "@mescius/spread-sheets-designer-resources-en";
import "@mescius/spread-sheets-formula-panel";
import "@mescius/spread-sheets-tablesheet";
import "@mescius/spread-sheets-ganttsheet";
import "@mescius/spread-sheets-io";
import "@mescius/spread-sheets-pivot-addon";
import "@mescius/spread-sheets-print";
import "@mescius/spread-sheets-reportsheet-addon";
import "@mescius/spread-sheets-shapes";
import "@mescius/spread-sheets-slicers";
// @ts-ignore https://developer.mescius.com/forums/spreadjs/spread-sheets-vue-with-typescript
import "@mescius/spread-sheets-designer-react";
import { Designer } from "@mescius/spread-sheets-designer-react";

import "@mescius/spread-sheets-designer/styles/gc.spread.sheets.designer.min.css";
import "@mescius/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import { initializeRollupIOSheet, renameInputsInCustomFunctions, updateInputsOnTheWorksheet } from "../utils/spreadsheetUtils";
import "./Spreadsheet.scss";

// NOTES: We can listen for two spreadsheet events to get the edited value:
// 1. EditEnded: This event is triggered after editing is completed. It is NOT triggered when a cell changes as a result of formula (re)calculation.
// 2. CalculationProgress: This event listens for calculation progress. It is triggered when a cell changes as a result of formula (re)calculation, but not when editing is done.
// We need to listen for both of them because the output values may point to a formula cell or a manual entry cell (as the user wishes).
// EditEnded may not always happen before the CalculationProgress event, so we need to listen for both (Example: consider a large sheet with long calculation time.
// SpreadJS allows for incremental calculation where the sheet is calculated in increments without blocking UI. While this calculation is ongoing, user can edit another cell).
// After both of these events are triggered, we need to get the edited value.

// Definitely check out cell binding for input/outputs: https://developer.mescius.com/spreadjs/docs/features/binding/bindcells#site_main_content-doc-content_title

export interface SpreadsheetProps {
  analysis: IAnalysis;
}

const Spreadsheet = ({ analysis }: SpreadsheetProps) => {
  const designerRef = useRef<DR.Spread.Sheets.Designer.Designer | null>(null);
  const [_designer, setDesigner] = useState<any>(null);
  const [workbook, setWorkbook] = useState<GC.Spread.Sheets.Workbook>();

  // Licensing SpreadJS
  // const SpreadJSKey = "xxx"; // Enter a valid license key.
  // GC.Spread.Sheets.LicenseKey = SpreadJSKey;

  useEffect(() => {
    const disposer = reaction(
      () =>
        analysis.inputs.map(input => ({
          id: input.id,
          label: input.label,
          value: input.value,
        })), // Data function: tracks all fields of all inputs
      (newInputs, oldInputs) => {
        if (analysis && workbook) {
          updateInputsOnTheWorksheet(workbook, newInputs, oldInputs); // May not be needed once I'm done.
          renameInputsInCustomFunctions(workbook, newInputs, oldInputs);
          workbook.calculate();
        }
      },
      {
        fireImmediately: false, // Optional: triggers immediately with the initial state. We may need this when running headless?
      }
    );
    // Clean up the listener on unmount
    return () => disposer();
  }, [analysis, workbook]);
  useEffect(() => {
    if (workbook) {
      // Listen to formula input (after editing is completed)
      workbook.bind(GC.Spread.Sheets.Events.EditEnded, (_event: any, args: any) => {
        const { row, col, sheet } = args;
        const newValue = sheet.getValue(row, col);
        console.debug(
          `Formula or value edited in sheet ${workbook.getActiveSheet().name()}, cell at row ${row + 1}, column ${col + 1}:`,
          newValue
        );
      });
      workbook.options.incrementalCalculation = true;
      workbook.bind(GC.Spread.Sheets.Events.CalculationProgress, function (_event: any, info: any) {
        if (info.pendingCells === 0) {
          console.debug("Calculation finished");
        }
      });
    }
  }, [workbook]);
  useEffect(() => {
    if (workbook) {
      initializeRollupIOSheet(workbook, analysis);
    }
  }, [workbook, analysis]);
  const handleDesignerInit = (designer: DR.Spread.Sheets.Designer.Designer) => {
    designerRef.current = designer;
    setDesigner(designerRef.current);
    const workbook = designerRef.current.getWorkbook();
    setWorkbook(workbook as GC.Spread.Sheets.Workbook);
  };
  return (
    <Designer styleInfo={{ width: "100%", height: "100%" }} spreadOptions={{ sheetCount: 3 }} designerInitialized={handleDesignerInit} />
  );
};

export default observer(Spreadsheet);
